import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  login(data: any) {
    let payload = {
      email: data.email,
      password: data.password,
      appTypeId: 3,
    };
    // console.log(payload, this.apiUrl);
    return this.http.post(`${this.apiUrl}api/Accounts/login`, payload);
    // .pipe(
    //   catchError((error) => {
    //     let errorMsg: string;
    //     if (error.error instanceof ErrorEvent) {
    //       errorMsg = `Error: ${error.error.message}`;
    //     } else {
    //       errorMsg = this.getServerErrorMessage(error);
    //     }

    //     return throwError(errorMsg);
    //   })
    // );
  }

  logOut() {
    return this.http.post(`${this.apiUrl}api/Accounts/logout`, null);
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400:
        return `Not Found: ${error.message}`;
        break;
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }
}

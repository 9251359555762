import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css'],
})
export class VerificationComponent implements OnInit {
  step: number = 2;

  constructor() {}

  ngOnInit(): void {}

  goForwardStep() {
    console.log(this.step);
    if (this.step > 5) {
      this.step = 1;
    } else {
      this.step++;
    }
    console.log(this.step);
  }

  goBackStep() {
    this.step--;
  }
}

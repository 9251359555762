import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { SeguridadService } from 'src/app/services/seguridad.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  errorLogin: string[] = [];
  carga: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private seguridadService: SeguridadService
  ) {}

  ngOnInit(): void {
    if (this.seguridadService.estaLogueado()) {
      this.router.navigate(['/home']);
    }

    this.form = this.formBuilder.group({
      email: ['', { validators: [Validators.required, Validators.email] }],
      password: ['', { validators: [Validators.required] }],
    });
  }

  onsubmit() {
    this.carga = true;
    this.errorLogin = [];
    this.loginService.login(this.form.value).subscribe(
      (response) => {
        this.seguridadService.guardarToken(response);
        localStorage.setItem(
          'NickName',
          this.seguridadService.obtenerCampoJWT('NickName')
        );
        localStorage.setItem(
          'ImagenPerfil',
          this.seguridadService.obtenerCampoJWT('PictureProfileUrl')
        );
        // console.log(response);
        // console.log(this.seguridadService.obtenerCampoJWT('EmailConfirmed'));

        this.router.navigate(['/home']);
      },
      (error) => {
        this.carga = false;
        console.log(error);
        if (error.status == 400) {
          error.error.forEach((element) => {
            this.errorLogin.push(element);
          });
        }
      }
    );
  }

  obtenerErrorUsuario() {
    var campo = this.form.get('email');
    if (campo.value.length !== 0) {
      if (campo.hasError('email')) {
        return 'Debe ingresar un email válido';
      }
    }
    return '';
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material/material/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './pages/shared/navbar/navbar.component';
import { SidemenuComponent } from './pages/shared/sidemenu/sidemenu.component';
import { SideMenuHomeComponent } from './pages/shared/side-menu-home/side-menu-home.component';
import { ProfesionalsComponent } from './pages/profesionals/profesionals.component';
import { AddProfesionalsComponent } from './pages/add-profesionals/add-profesionals.component';
import { SeguridadInterceptorService } from './services/seguridad-interceptor.service';
import { FormularioDatosComponent } from './pages/shared/formulario-datos/formulario-datos.component';
import { FormularioClinicaComponent } from './pages/shared/formulario-clinica/formulario-clinica.component';
import { FormularioEspecialidadesComponent } from './pages/shared/formulario-especialidades/formulario-especialidades.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { EspecialidadSearchComponent } from './pages/shared/especialidad-search/especialidad-search.component';
import { FormularioEspecialidadComponent } from './pages/shared/formulario-especialidad/formulario-especialidad.component';
import { SideMenuEspecialidadComponent } from './pages/shared/side-menu-especialidad/side-menu-especialidad.component';
import { FormularioAsocionesComponent } from './pages/shared/formulario-asociones/formulario-asociones.component';
import { NewEspecialityComponent } from './pages/new-especiality/new-especiality.component';
import { VerificationComponent } from './pages/verification/verification.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    SidemenuComponent,
    SideMenuHomeComponent,
    ProfesionalsComponent,
    AddProfesionalsComponent,
    FormularioDatosComponent,
    FormularioClinicaComponent,
    FormularioEspecialidadesComponent,
    EspecialidadSearchComponent,
    FormularioEspecialidadComponent,
    SideMenuEspecialidadComponent,
    FormularioAsocionesComponent,
    NewEspecialityComponent,
    VerificationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    LeafletModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SeguridadInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class SeguridadService {
  constructor(private loginService: LoginService, private http: HttpClient) {}
  private readonly llaveToken = 'token';
  private readonly llaveExpiracion = 'expiration';
  private apiUrl = environment.apiUrl;

  estadoCuenta: string = '';

  public get getEstadoCuenta(): string {
    return this.estadoCuenta;
  }

  public set setEstadoCuenta(v: string) {
    this.estadoCuenta = v;
  }

  estaLogueado(): boolean {
    const token = localStorage.getItem(this.llaveToken);

    if (!token) {
      return false;
    }

    const expiracion = localStorage.getItem(this.llaveExpiracion);
    const expiracionFecha = new Date(expiracion);

    if (expiracionFecha <= new Date()) {
      this.logout();
      return false;
    }

    return true;
  }

  logout() {
    localStorage.removeItem(this.llaveToken);
    localStorage.removeItem(this.llaveExpiracion);
    localStorage.removeItem('ImagenPerfil');
    this.loginService.logOut();
  }

  obtenerCampoJWT(campo: string): string {
    const token = localStorage.getItem(this.llaveToken);

    if (!token) {
      return '';
    }

    var dataToken = JSON.parse(atob(token.split('.')[1]));
    return dataToken[campo];
  }

  guardarToken(respuesta: any) {
    localStorage.setItem(this.llaveToken, respuesta.token);
    localStorage.setItem(this.llaveExpiracion, respuesta.expiration);
  }

  obtenerToken() {
    return localStorage.getItem(this.llaveToken);
  }

  obtenerConfirmado(): boolean {
    const confirmado = this.obtenerCampoJWT('emailConfirmed');
    if (confirmado === 'Confirmado') {
      return true;
    }

    return false;
  }

  obtenerStatus() {
    const userId = this.obtenerCampoJWT('UserId');
    return this.http.get(`${this.apiUrl}api/Accounts/status/${userId}`);
  }
}

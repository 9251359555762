<app-navbar></app-navbar>

<div class="contenedor">
  <div class="left">
    <app-sidemenu
      (opcion)="eventoOpcionActivo($event)"
      [opcionActiva]="opcionActiva"
    ></app-sidemenu>
  </div>
  <div class="posting-container">
    <div class="center-formulario">
      <app-formulario-datos
        *ngIf="opcionActiva === 1"
        (opcionActiva)="cambiarOpcion($event)"
      ></app-formulario-datos>
      <app-formulario-clinica
        *ngIf="opcionActiva === 2"
      ></app-formulario-clinica>
      <app-formulario-especialidades
        *ngIf="opcionActiva === 3"
      ></app-formulario-especialidades>

      <div class="d-flex justify-content-around">
        <button
          class="boton"
          (click)="regresar()"
          *ngIf="opcionActiva !== 1 && !existIdP"
        >
          Regresar
        </button>
        <button class="boton" *ngIf="opcionActiva === 2" (click)="clickIr()">
          Continuar
        </button>
        <button class="boton" *ngIf="opcionActiva === 3" routerLink="/home">
          Guardar
        </button>
      </div>
    </div>
  </div>
  <div class="right"></div>
</div>

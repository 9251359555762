import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddProfesionalsComponent } from './pages/add-profesionals/add-profesionals.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NewEspecialityComponent } from './pages/new-especiality/new-especiality.component';
import { ProfesionalsComponent } from './pages/profesionals/profesionals.component';
import { VerificationComponent } from './pages/verification/verification.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'profesionals', component: ProfesionalsComponent },
  { path: 'new', component: AddProfesionalsComponent },
  { path: 'newEspeciality', component: NewEspecialityComponent },
  { path: 'verification', component: VerificationComponent },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DatosService } from 'src/app/services/datos.service';
import { Professional } from 'src/app/utilidades/interfaces';
import { SeguridadService } from 'src/app/services/seguridad.service';
import { element } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-formulario-especialidades',
  templateUrl: './formulario-especialidades.component.html',
  styleUrls: ['./formulario-especialidades.component.css'],
})
export class FormularioEspecialidadesComponent implements OnInit {
  imagenBase64;
  correo;
  seleccionado = 0;
  @ViewChild('content') content: any;
  @ViewChild('content1') content1: any;

  closeResult = '';
  @Input() datos: Professional = undefined;
  especialidadArray = [];
  datosProfesional;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  idprofesional;
  /* */
  especialidades;
  /** */
  nickName: string = '';
  especialidadSeleccionada: string = '';
  idProfesional: number = 0;

  constructor(
    private modalService: NgbModal,
    private datosService: DatosService,
    private seguridadService: SeguridadService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['Pro'] !== undefined) {
        this.idProfesional = parseInt(params['Pro']);
        this.datosService
          .getSpecialismsByProfessionalId(this.idProfesional)
          .subscribe((resposne) => {
            // console.log(resposne);
            this.datosProfesional = resposne;
            this.datosService.getSpecialism().subscribe((response) => {
              this.especialidades = response;
              if (
                this.especialidadArray.length === 0 &&
                this.datosService.especialidades.length === 0
              ) {
                this.datosProfesional.forEach((element2) => {
                  this.especialidades.filter((element) => {
                    if (element.name == element2.name) {
                      this.especialidadArray.push({
                        id: element.id,
                        name: element.name,
                      });
                    }
                  });
                });
              }
            });
          });
      }
    });
  }

  ngOnInit(): void {
    this.datos = this.datosService.infoProfessional;
    // this.datosProfesional = this.datos;
    this.datosProfesional = this.datosService.ProfesionalPut.specialisms;
    this.especialidadArray = this.datosService.especialidades;

    this.datosService.getSpecialism().subscribe((response) => {
      this.especialidades = response;
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.datosService.especialidades = [];
  }

  agregarEspecialidad() {
    // console.log(this.seleccionado);
    if (this.seleccionado !== 0) {
      let busqueda = this.especialidades.filter(
        (element) => element.id == this.seleccionado
      );

      let verificar = this.especialidadArray.filter(
        (element) => element.id == busqueda[0].id
      );

      if (verificar.length === 0) {
        this.especialidadSeleccionada = busqueda[0].name;
        this.especialidadArray.push(busqueda[0]);

        if (
          this.router.url.split('/')[1] === 'new' ||
          this.router.url.split('/')[1].split('?')[0] === 'new'
        ) {
          this.idprofesional =
            this.idProfesional === 0
              ? localStorage.getItem('idP')
              : this.idProfesional;

          this.datosService
            .postSpecialism(this.idprofesional, this.seleccionado)
            .subscribe((response) => {
              // console.log(response);
            });
        }
      }
      this.datosService.especialidades = this.especialidadArray;
    }
  }

  quitarEspecialidad(id) {
    // console.log(id);
    this.especialidadArray = this.especialidadArray.filter(
      (element) => element.id != id
    );
    this.idprofesional =
      this.idProfesional === 0
        ? localStorage.getItem('idP')
        : this.idProfesional;

    this.datosService
      .deleteSpecialism(this.idprofesional, id)
      .subscribe((response) => {
        // console.log(response);
      });

    this.datosService.especialidades = this.especialidadArray;
  }

  /*Modal*/
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}

import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DatosService } from 'src/app/services/datos.service';
import { Professional } from 'src/app/utilidades/interfaces';
import { SeguridadService } from 'src/app/services/seguridad.service';
import { element } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AsociationPost } from 'src/app/utilidades/interfaceAsociation';

@Component({
  selector: 'app-formulario-asociones',
  templateUrl: './formulario-asociones.component.html',
  styleUrls: ['./formulario-asociones.component.css'],
})
export class FormularioAsocionesComponent implements OnInit {
  seleccionado = 0;
  @ViewChild('content') content: any;
  @ViewChild('content1') content1: any;

  closeResult = '';
  @Input() datos: Professional = undefined;
  especialidadArray = [];
  /* */
  nickName: string = '';
  //
  form: FormGroup;

  asociationObject: AsociationPost = {
    name: '',
    phoneNumber: '',
    address: '',
    email: '',
  };
  responsePostAssociation;
  responseGetAssociation;
  responseEspecialidad;
  idAsociacion: number = 0;
  idEspecialidad: number = 0;
  idModificacion: number = 0;

  accion: number = 1;

  constructor(
    private modalService: NgbModal,
    private datosService: DatosService,
    private seguridadService: SeguridadService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['Spe'] !== undefined) {
        this.idEspecialidad = parseInt(params['Spe']);

        this.datosService
          .obtaionAssociationsBySpecialisms(this.idEspecialidad)
          .subscribe((response) => {
            this.responseEspecialidad = response;
            this.responseEspecialidad.forEach((element) => {
              this.especialidadArray.push({
                name: element.name,
                associationId: element.associationId,
              });
            });
          });
      } else {
        this.idEspecialidad = parseInt(localStorage.getItem('idS'));
      }
    });
  }

  ngOnInit(): void {
    this.parchearValor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }
  parchearValor() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', Validators.required],
    });
  }

  agregarAsociacion() {
    this.accion = 1;
    this.modalService.dismissAll();

    this.asociationObject = {
      address: this.form.value.address,
      email: this.form.value.email,
      name: this.form.value.name,
      phoneNumber: this.form.value.phone,
    };

    this.datosService
      .postAsociation(this.asociationObject)
      .subscribe((response) => {
        this.responsePostAssociation = response;

        this.datosService
          .realtionAssociation(
            this.responsePostAssociation.associationId,
            this.idEspecialidad
          )
          .subscribe((response) => {
            this.datosService
              .obtaionAssociationsBySpecialisms(this.idEspecialidad)
              .subscribe((response) => {
                this.responseEspecialidad = response;

                this.especialidadArray = [];
                this.responseEspecialidad.forEach((element) => {
                  this.especialidadArray.push({
                    name: element.name,
                    associationId: element.associationId,
                  });
                });
                this.parchearValor();
              });
          });
      });
  }

  modificarAsociacion() {
    this.asociationObject = {
      address: this.form.value.address,
      email: this.form.value.email,
      name: this.form.value.name,
      phoneNumber: this.form.value.phone,
    };

    this.datosService
      .putAssociation(this.idModificacion, this.asociationObject)
      .subscribe((response) => {
        this.datosService
          .obtaionAssociationsBySpecialisms(this.idEspecialidad)
          .subscribe((response) => {
            this.responseEspecialidad = response;

            this.especialidadArray = [];
            this.responseEspecialidad.forEach((element) => {
              this.especialidadArray.push({
                name: element.name,
                associationId: element.associationId,
              });
            });
            this.parchearValor();
            this.modalService.dismissAll();
          });
      });
  }

  modificar(id: number = 0) {
    this.accion = 2;
    this.idModificacion = id;
    this.modalService.open(this.content);
    this.datosService.getAssociationById(id).subscribe((response) => {
      this.responseGetAssociation = response;
      this.form.patchValue({
        name: this.responseGetAssociation.name,
        phone: this.responseGetAssociation.phoneNumber,
        address: this.responseGetAssociation.address,
        email: this.responseGetAssociation.email,
      });
    });
  }

  quitarAsociacion(id: number) {
    this.especialidadArray = this.especialidadArray.filter(
      (element) => element.associationId !== id
    );

    this.datosService
      .disableAssociation(id, this.idEspecialidad)
      .subscribe((response) => {});
  }

  keyPress(event) {
    var charcode = event.which ? event.which : event.charCode;
    // console.log(charcode);
    if (charcode < 48 || charcode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  /*Modal*/
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}

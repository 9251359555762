<div>
  <form (submit)="guardar($event)" [formGroup]="form">
    <h5 class="bold form-label">Datos Personales</h5>
    <div class="form-row">
      <div class="form-group col-12 col-lg-6">
        <label class="bold form-label">Tipo *</label>
        <select class="form-control form-select" formControlName="tipo">
          <option value="0">Seleccione ...</option>
          <option [value]="item.id" *ngFor="let item of prefijos">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-12 col-lg-6">
        <label class="bold form-label">Especialidad *</label>
        <input
          type="text"
          class="form-control"
          formControlName="especialidad"
        />
      </div>
    </div>
    <!--  -->
    <!--  -->
    <!--  -->

    <!--  -->

    <!--  -->
    <div class="form-row mt-5">
      <div class="form-group col-12 col-lg-6 d-flex">
        <div class="form-row" *ngIf="form.invalid">
          <mat-error
            >* Falta completar la información necesaria para
            continuar</mat-error
          >
        </div>
      </div>
      <div class="form-group col-12 col-lg-6 d-flex">
        <button type="submit" class="btn boton" [disabled]="form.invalid">
          {{ mensaje }}
        </button>
      </div>
    </div>
    <!--  -->
  </form>
</div>

<div *ngIf="cargando" class="carga">
  <span class="loader"></span>
</div>

<mat-toolbar color="navBar" class="d-none d-sm-flex">
  <!-- Muestra back si no esta en la pantalla home -->
  <div class="back d-none d-sm-block" *ngIf="!mostrarElementos">
    <a (click)="back()">
      <img src="../assets/arrow.png" alt="Logo" class="back2" />
    </a>
  </div>
  <!--  -->
  <a (click)="redirigir('home')"
    ><img src="../assets/Nombre copia 2.png" alt="Nombre" class="logo-navbar"
  /></a>

  <div class="d-flex justify-content-end opciones">
    <div ngbDropdown class="d-inline-block drop">
      <button class="btn d-inline-block" id="dropdownBasic1" ngbDropdownToggle>
        <div mat-card-avatar class="image d-inline-block">
          <img
            src="../assets/profile-user.png"
            alt="Logo"
            class="user"
            style="object-fit: cover"
          />
          <!-- <div><span>Carlos Laib</span></div> -->
        </div>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <!-- <button ngbDropdownItem routerLink="/user-profile">Perfil</button> -->
        <!-- <button ngbDropdownItem (click)="redirigir('profile')">Perfil</button> -->
        <button ngbDropdownItem (click)="logout()">Cerrar Sesión</button>
      </div>
    </div>
  </div>
</mat-toolbar>
<!-- Resoluciones Pequeñas -->
<mat-toolbar color="navBar" class="d-block d-sm-none barra-secundaria">
  <div class="d-flex justify-content-start">
    <button class="boton-menu" (click)="changeToogle()">
      <img src="../assets/menu.png" alt="Nombre" class="" />
    </button>
    <a routerLink="/home" class="d-block- d-sm-none"
      ><img src="../assets/Nombre.png" alt="Nombre" class=""
    /></a>
  </div>
  <div class="menu-lado" [class]="changeCss(ocultar)" (click)="changeToogle()">
    <div class="menu-interno" (click)="$event.stopPropagation()">
      <ul>
        <li routerLink="/home">Inicio</li>
        <li (click)="redirigir('profile')">Perfil</li>
        <li (click)="logout()">Cerrar Sesión</li>
      </ul>
    </div>
  </div>
</mat-toolbar>

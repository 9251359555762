<div class="options d-none d-sm-block">
  <div
    (click)="activarOpcion(1)"
    [class]="getClaseCSS(1)"
    routerLink="/profesionals"
  >
    <mat-icon class="iconos">
      <svg
        class="iconos"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="enable-background: new 0 0 512 512"
        xml:space="preserve"
        width="512"
        height="512"
      >
        <g>
          <circle cx="256" cy="128" r="128" />
          <path
            d="M256,298.667c-105.99,0.118-191.882,86.01-192,192C64,502.449,73.551,512,85.333,512h341.333   c11.782,0,21.333-9.551,21.333-21.333C447.882,384.677,361.99,298.784,256,298.667z"
          />
        </g>
      </svg>
    </mat-icon>
    <span>Profesionales</span>
  </div>
  <div (click)="activarOpcion(2)" [class]="getClaseCSS(2)">
    <mat-icon class="iconos">
      <svg
        class="iconos"
        style="transform: rotate(-90deg)"
        xmlns="http://www.w3.org/2000/svg"
        id="Filled"
        viewBox="0 0 24 24"
      >
        <path
          d="M6,24H8.13a2.02,2.02,0,0,0,1.941-1.532,2,2,0,0,1,3.858,0A2.02,2.02,0,0,0,15.87,24H18a3,3,0,0,0,3-3V17H17a1,1,0,0,1,0-2h4V5a5.006,5.006,0,0,0-5-5h-.13a2.02,2.02,0,0,0-1.941,1.532,2,2,0,0,1-3.858,0A2.02,2.02,0,0,0,8.13,0H8A5.006,5.006,0,0,0,3,5V15H7a1,1,0,0,1,0,2H3v4A3,3,0,0,0,6,24Zm5-9h2a1,1,0,0,1,0,2H11a1,1,0,0,1,0-2Z"
        />
      </svg>
    </mat-icon>
    <span>Proveedores</span>
  </div>
  <div (click)="activarOpcion(3)" [class]="getClaseCSS(3)">
    <mat-icon class="iconos">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36.11 33.1"
        class="iconos"
      >
        <defs>
          <style>
            .cls-1 {
              fill: #4db9d5;
            }
          </style>
        </defs>
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <path
              id="agregar-carpeta"
              class="cls-1"
              d="M35.9,8.85,0,9V7.52A7.52,7.52,0,0,1,7.52,0h3.8a4.45,4.45,0,0,1,2,.48l4.75,2.38a1.56,1.56,0,0,0,.67.15h9.82A7.51,7.51,0,0,1,35.9,8.85Zm.21,3V25.58a7.54,7.54,0,0,1-7.53,7.52H7.52A7.52,7.52,0,0,1,0,25.58V12Zm-12,10.72a1.5,1.5,0,0,0-1.5-1.5h-3v-3a1.51,1.51,0,0,0-3,0v3h-3a1.51,1.51,0,0,0,0,3h3v3a1.51,1.51,0,0,0,3,0v-3h3a1.5,1.5,0,0,0,1.5-1.49Z"
            />
          </g>
        </g>
      </svg>
    </mat-icon>
    <span>Expedientes</span>
  </div>
</div>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css'],
})
export class SidemenuComponent implements OnInit {
  @Input() opcionActiva: number = 1;
  estadoResponse: any;
  mensaje: string = '';
  validado: boolean = true;

  @Output() opcion: EventEmitter<number> = new EventEmitter<number>();
  constructor(private router: Router) {}

  ngOnInit(): void {}

  getClaseCSS(opcion: number) {
    if (this.router.url.split('/')[1] === 'profile') {
      return opcion === this.opcionActiva
        ? 'options-sidemenu active'
        : 'options-sidemenu';
    } else {
      return opcion === this.opcionActiva
        ? 'options-sidemenu active bloquear'
        : 'options-sidemenu bloquear';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }

  activarOpcion(opcionActiva: number) {
    if (opcionActiva === this.opcionActiva) {
      return;
    }
    this.opcionActiva = opcionActiva;
    this.opcion.emit(this.opcionActiva);
  }
}

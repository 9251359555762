import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DatosService } from 'src/app/services/datos.service';
import { SearchService } from 'src/app/services/search.service';
import { SeguridadService } from 'src/app/services/seguridad.service';
import {
  Professional,
  SpecialismCreatorDto,
} from 'src/app/utilidades/interfaces';
@Component({
  selector: 'app-formulario-especialidad',
  templateUrl: './formulario-especialidad.component.html',
  styleUrls: ['./formulario-especialidad.component.css'],
})
export class FormularioEspecialidadComponent implements OnInit {
  form: FormGroup;
  nickName;
  @Input() datos: Professional;
  prefijos;
  cargando: boolean = false;
  mensaje: string = 'Continuar';
  @Output() opcionActiva: EventEmitter<number> = new EventEmitter<number>();
  ocultar: boolean = false;

  SpecialismCreateDto: SpecialismCreatorDto = {
    specialismTypeId: 0,
    name: '',
    description: '',
  };

  idEspecialidad: number = 0;
  respuesta;
  respuestaPost;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private datosService: DatosService,
    private seguridadService: SeguridadService,
    private searchService: SearchService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['Spe'] !== undefined) {
        this.idEspecialidad = parseInt(params['Spe']);
      }
    });
  }

  ngOnInit(): void {
    this.nickName = this.seguridadService.obtenerCampoJWT('NickName');
    this.datos = this.datosService.infoProfessional;
    this.searchService.getTypesSpecialism().subscribe((response) => {
      this.prefijos = response;
      // console.log(this.prefijos);
    });
    this.parchearValor();

    if (this.idEspecialidad !== 0) {
      this.cargando = true;
      this.datosService
        .getSpecialisById(this.idEspecialidad)
        .subscribe((response) => {
          this.cargando = false;
          this.respuesta = response;
          this.form = this.formBuilder.group({
            tipo: [this.respuesta.specialismTypeId, Validators.required],
            especialidad: [this.respuesta.name, Validators.required],
          });
        });
    }

    // console.log(this.datos);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.datos !== undefined) {
      if (this.datos.id !== 0) {
        // console.log(this.datos);
        this.parchearValor();
      }
    }
  }

  keyPress2(event) {
    var charcode = event.which ? event.which : event.charCode;
    // console.log(charcode);
    if (charcode >= 65 && charcode <= 90) {
      return true;
    } else if (charcode >= 97 && charcode <= 122) {
      return true;
    } else if (charcode >= 48 && charcode <= 57) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  parchearValor() {
    this.form = this.formBuilder.group({
      tipo: ['', Validators.required],
      especialidad: ['', Validators.required],
    });
  }

  guardar(e) {
    if (
      this.form.value.tipo === '' &&
      this.form.value.tipo === null &&
      this.form.value.tipo === undefined
    ) {
      // console.log(this.form.value.tipo);
    } else if (
      this.form.value.especialidad === '' &&
      this.form.value.especialidad === null &&
      this.form.value.especialidad === undefined
    ) {
      // console.log(this.form.value.especialidades);
    } else {
      this.SpecialismCreateDto.specialismTypeId = this.form.value.tipo;
      this.SpecialismCreateDto.description = this.form.value.especialidad;
      this.SpecialismCreateDto.name = this.form.value.especialidad;
      // console.log(this.SpecialismCreateDto);

      if (this.idEspecialidad === 0) {
        this.datosService
          .createSpecialism(this.SpecialismCreateDto)
          .subscribe((response) => {
            this.respuestaPost = response;
            localStorage.setItem('idS', this.respuestaPost.specialismId);
            this.opcionActiva.emit(2);
          });
      } else {
        this.datosService
          .putSpecialismsById(this.idEspecialidad, this.SpecialismCreateDto)
          .subscribe((response) => {
            this.opcionActiva.emit(2);
          });
      }
    }
  }

  regresar() {
    this.opcionActiva.emit(1);
  }

  keyPress(event) {
    var charcode = event.which ? event.which : event.charCode;
    if (charcode < 48 || charcode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}

<app-navbar></app-navbar>

<div class="container-fluid mb-4">
  <div class="row pt-4 pb-5 d-flex justify-content-center align-items-center">
    <a
      class="pl-5 pr-5 izquierda"
      (click)="activarOpcion(1)"
      [class]="getClaseCSS(1)"
      >Profesionales</a
    >
    <a
      class="pl-5 pr-5 centro"
      (click)="activarOpcion(2)"
      [class]="getClaseCSS(2)"
      >Especialidades</a
    >
    <a
      class="pl-5 pr-5 derecha"
      (click)="activarOpcion(3)"
      [class]="getClaseCSS(3)"
      >Centros</a
    >
  </div>

  <!-- Opcion 1 -->
  <div *ngIf="opcionActiva === 1">
    <div class="row">
      <div class="col-12 col-sm-2"></div>
      <div class="col-12 col-sm-3">
        <div class="form-group">
          <label class="form-label title-one-p">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20.64 20.64"
                class="iconos-svg"
                routerLink="/new"
              >
                <defs>
                  <style>
                    .cls-1 {
                      fill: #2a93cd;
                      stroke: #2a93cd;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-width: 6px;
                    }
                  </style>
                </defs>
                <g id="Capa_2" data-name="Capa 2">
                  <g id="Capa_1-2" data-name="Capa 1">
                    <g id="Capa_1-3" data-name="Capa 1">
                      <line
                        id="Línea_8"
                        data-name="Línea 8"
                        class="cls-1"
                        x1="10.27"
                        y1="3"
                        x2="10.37"
                        y2="17.64"
                      />
                      <line
                        id="Línea_9"
                        data-name="Línea 9"
                        class="cls-1"
                        x1="17.64"
                        y1="10.23"
                        x2="3"
                        y2="10.41"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              Profesional</span
            >
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Nombre o Dirección"
            [(ngModel)]="busqueda"
            (keypress)="buscar2($event)"
          />
        </div>
      </div>
      <div class="col-12 col-sm-3">
        <div class="form-group">
          <label class="form-label title-one-p">Especialidad</label>
          <!-- <select class="form-control" [(ngModel)]="especialidad">
            <option value="0">Seleccione ...</option>
            <option *ngFor="let item of especialidades" [value]="item.id">
              {{ item.name }}
            </option>
          </select> -->
          <mat-form-field
            class="example-full-width"
            appearance="none"
            style="width: 100%"
          >
            <!-- <mat-label>Especialidad</mat-label> -->
            <input
              type="text"
              aria-label="Number"
              matInput
              class="form-control"
              placeholder="Seleccione ..."
              [formControl]="myControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-sm-4 pl-5 pr-5 row">
        <div class="d-flex align-items-center pl-1 col-2">
          <button class="btn boton" (click)="buscar()">
            <img src="../assets/search.svg" alt="" class="iconos" />
          </button>
        </div>
      </div>
    </div>
    <div class="row pl-5 pr-5 pt-3">
      <span class="pr-5 res">Se encontraron {{ cantidad }} resultados</span>
      <span class="mapa" (click)="mostrarMapa()">VER MAPA</span>
    </div>
    <div class="resultado-container pt-4">
      <div class="resultados" *ngIf="!vermapa">
        <div
          class="resultado row mb-2"
          *ngFor="let item of respuestaBusqueda"
          (click)="redireccion(item.professionalId, item.applicationUserId)"
        >
          <div class="col-12 col-sm-3 d-flex align-items-center">
            <div class="row" style="width: 100%">
              <div class="col-12 col-xl-9">
                <span class="pr-1 nombre"
                  >{{ item.prefix }} {{ item.fullName }}
                </span>
                <span class="check" *ngIf="item.perfil"
                  ><img src="../assets/check2.png" alt="validado"
                /></span>
              </div>
              <div class="col-12 col-xl-3">
                <img
                  *ngIf="item.pictureProfileUrl !== null"
                  [src]="item.pictureProfileUrl"
                  class="imagen-doctor"
                  alt="Imagen doctor"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3 d-flex flex-column">
            <span *ngFor="let item2 of item.specialisms">
              <span class="mas">+</span> {{ item2.name }}</span
            >
          </div>
          <div class="col-12 col-sm-6 d-flex flex-column">
            <div class="row" *ngFor="let item3 of item.clinics">
              <span *ngIf="item3.enabled" class="col-12 col-xl-10">
                <span class="mas">+</span>{{ item3.name }},
                {{ item3.address }}
                <!-- , -->
                <!-- {{ item3.phoneNumber }} -->
              </span>
              <span *ngIf="item3.enabled" class="col-12 col-xl-2 mb-1 mt-1"
                ><div class="badge p-2">{{ item3.phoneNumber }}</div></span
              >
            </div>
            <!-- <span *ngFor="let item3 of item.clinics">
            </span> -->
          </div>
          <!-- <div class="col-12 col-sm-2 d-flex flex-column">
            <span *ngFor="let item3 of item.clinics">
              <span *ngIf="item3.enabled">
                <span class="badge mb-1 mt-1 p-2 align-items-center">{{
                  item3.phoneNumber
                }}</span>
              </span>
            </span> -->
          <!-- </div> -->
        </div>
        <div class="d-flex justify-content-center" *ngIf="sinResultados">
          Sin Resultados
        </div>
        <div *ngIf="cargando" class="carga">
          <span class="loader"></span>
        </div>
      </div>
      <div
        *ngIf="vermapa"
        style="height: 500px"
        leaflet
        [leafletOptions]="options"
        [leafletLayers]="capas"
      ></div>
    </div>
  </div>
</div>

<div *ngIf="opcionActiva === 2">
  <app-especialidad-search
    (cambio)="obtenerCambio($event)"
  ></app-especialidad-search>
</div>

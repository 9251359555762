import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SeguridadService } from 'src/app/services/seguridad.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  mostrarElementos: boolean = true;
  imagen: string = '../assets/profile-user.png';
  @Input() cambio: number = 0;
  ocultar: boolean = false;

  constructor(
    private router: Router,
    private location: Location,
    private seguridadService: SeguridadService
  ) {}

  ngOnInit(): void {
    if (
      this.router.url.split('/')[1] === 'profesionals' ||
      this.router.url.split('/')[1] === 'new' ||
      this.router.url.split('/')[1] === 'edit-post' ||
      this.router.url.split('/')[1].split('?')[0] === 'new'
    ) {
      this.mostrarElementos = false;
    } else {
      this.mostrarElementos = true;
    }

    if (
      this.router.url.split('/')[1] !== 'user-information' &&
      this.router.url.split('/')[1] !== 'user-profile'
    ) {
    }

    if (localStorage.getItem('ImagenPerfil')) {
      if (
        localStorage.getItem('ImagenPerfil') === 'Pendiente' ||
        this.seguridadService.obtenerCampoJWT('PictureProfileUrl') === null
      ) {
        this.imagen = '../assets/profile-user.png';
      } else {
        this.imagen = localStorage.getItem('ImagenPerfil');
      }
    } else {
      this.imagen = '../assets/profile-user.png';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.cambio === 1) {
      this.imagen = localStorage.getItem('ImagenPerfil');
      this.cambio = 0;
    }
  }

  logout() {
    localStorage.removeItem('NickName');
    this.seguridadService.logout();
    this.router.navigate(['/login']);
  }

  back() {
    this.location.back();
  }

  changeToogle() {
    this.ocultar = !this.ocultar;
  }

  changeCss(cambio: boolean) {
    return cambio === true ? 'menu-lado' : 'menu-lado-d';
  }

  redirigir(ruta) {
    if (
      this.router.url.split('/')[1] !== 'user-information' &&
      this.router.url.split('/')[1] !== 'user-profile'
    ) {
      this.router.navigate([`/${ruta}`]);
    }
  }
}

<app-navbar></app-navbar>

<div class="contenedor">
  <div class="left">
    <app-side-menu-especialidad
      (opcion)="eventoOpcionActivo($event)"
      [opcionActiva]="opcionActiva"
    ></app-side-menu-especialidad>
  </div>
  <div class="posting-container">
    <div class="center-formulario">
      <app-formulario-especialidad
        (opcionActiva)="cambiarOpcion($event)"
        *ngIf="opcionActiva === 1"
      ></app-formulario-especialidad>
      <app-formulario-asociones
        *ngIf="opcionActiva === 2"
      ></app-formulario-asociones>

      <div class="d-flex justify-content-around">
        <!-- <button class="boton" (click)="regresar()" *ngIf="opcionActiva !== 1">
          Regresar
        </button> -->
        <button
          class="boton"
          *ngIf="opcionActiva === 2"
          routerLink="/profesionals"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
  <div class="right"></div>
</div>

import { Component, OnInit } from '@angular/core';
import { DatosService } from 'src/app/services/datos.service';

@Component({
  selector: 'app-add-profesionals',
  templateUrl: './add-profesionals.component.html',
  styleUrls: ['./add-profesionals.component.css'],
})
export class AddProfesionalsComponent implements OnInit {
  opcionActiva = 1;
  existIdP: boolean = false;

  constructor(private datosService: DatosService) {
    localStorage.removeItem('idP');
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    localStorage.removeItem('idP');
    this.datosService.infoProfessional = {
      address: '',
      birthdate: '',
      collegiateNumber: '',
      dpi: '',
      firstName: '',
      genderId: 0,
      id: 0,
      lastName: '',
      marriedName: '',
      nickName: '',
      phoneNumber: '',
      pictureProfileUrl: '',
      profile: '',
      secondName: '',
      surName: '',
      thirdName: '',
      maritalStatusId: 0,
      prefixId: 0,
      profesionalId: 0,
      services: '',
      surname: '',
    };
  }

  eventoOpcionActivo(e) {
    if (localStorage.getItem('idP')) {
      this.existIdP = true;
    }
    this.opcionActiva = e;
  }

  cambiarOpcion(e) {
    if (localStorage.getItem('idP')) {
      this.existIdP = true;
    }
    this.opcionActiva = e;
  }

  regresar() {
    this.opcionActiva--;
  }

  clickIr() {
    this.opcionActiva++;
  }
}

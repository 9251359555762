import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  tileLayer,
  latLng,
  LeafletMouseEvent,
  Marker,
  marker,
  icon,
} from 'leaflet';
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-profesionals',
  templateUrl: './profesionals.component.html',
  styleUrls: ['./profesionals.component.css'],
})
export class ProfesionalsComponent implements OnInit {
  especialidades;
  vermapa: boolean = false;
  busqueda;
  sinResultados: boolean = true;
  especialidad = 0;
  capas: Marker<any>[] = [];
  respuestaBusqueda: any[] = [];
  cargando: boolean = false;
  cantidad: number = 0;
  myControl = new FormControl();
  options2 = [];
  filteredOptions: Observable<string[]>;
  opcionActiva: number = 1;

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.searchService.getSpecialism().subscribe((response) => {
      this.especialidades = response;
      this.especialidades.forEach((element) => {
        this.options2.push(element);
      });

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter(name) : this.options2.slice()))
      );
    });
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options2.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.route.queryParams.subscribe((params) => {
      this.sinResultados = false;
      // console.log(params.search);
      if (params.search !== undefined) {
        this.busqueda = params.search;
        this.cargando = true;
        this.searchService.search(this.busqueda).subscribe((response) => {
          this.respuestaBusqueda = response;
          // console.log(this.respuestaBusqueda);
          this.cantidad = this.respuestaBusqueda.length;
          this.cargando = false;
          this.sinResultados = false;
          if (this.respuestaBusqueda.length !== 0) {
            this.respuestaBusqueda.forEach((element) => {
              this.agregarMapa(element.pictureProfileUrl, element.clinics);
            });
          } else {
            this.sinResultados = true;
          }
        });
      } else {
        this.sinResultados = true;
      }
    });
  }

  options = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '...',
      }),
    ],
    zoom: 15,
    center: latLng(14.642582, -90.513447),
  };

  mostrarMapa() {
    this.vermapa = !this.vermapa;
  }

  redireccion(idProfesional: any, applicationUserId: any) {
    // console.log(idProfesional);
    this.router.navigate([`/new`], {
      queryParams: { us: applicationUserId, Pro: idProfesional },
    });
  }

  buscar2(e) {
    if (e.keyCode === 13) {
      this.buscar();
    }
  }

  buscar() {
    this.respuestaBusqueda = [];
    this.vermapa = false;
    this.sinResultados = false;
    this.cargando = true;
    this.cantidad = 0;

    // console.log(this.myControl.value);
    if (this.busqueda === undefined) {
      this.busqueda = '';
    }

    if (this.myControl.value === null || this.myControl.value === '') {
      this.especialidad = 0;
    } else {
      this.especialidad = this.myControl.value.id;
    }

    // if (this.busqueda !== '' || this.especialidad !== -1) {
    // console.log(this.especialidad, this.busqueda);
    this.searchService
      .search(this.busqueda, parseInt(this.especialidad.toString()))
      .subscribe((response) => {
        this.capas = [];
        this.respuestaBusqueda = response;
        this.cargando = false;
        this.cantidad = this.respuestaBusqueda.length;
        // console.log(this.respuestaBusqueda);
        // console.table(this.respuestaBusqueda);
        if (this.respuestaBusqueda.length !== 0) {
          this.respuestaBusqueda.forEach((element) => {
            this.agregarMapa(element.pictureProfileUrl, element.clinics);
          });
        } else {
          this.sinResultados = true;
        }
      });
    // } else {
    //   this.cargando = false;
    //   this.sinResultados = true;
    //   this.respuestaBusqueda = [];
    //   this.capas = [];
    // }
  }
  //
  obtenerCambio(idEspecialidad: number = 0) {
    // console.log(idEspecialidad);
    if (idEspecialidad !== 0) {
      this.activarOpcion(1);
      this.getClaseCSS(1);

      let busqueda = this.especialidades.filter((x) => x.id === idEspecialidad);
      // console.log(busqueda);
      this.myControl.setValue(busqueda[0]);
      this.busqueda = '';
      this.buscar();
    }
  }

  //

  getClaseCSS(opcion: number) {
    return opcion === this.opcionActiva ? '' : 'desactivado';
  }

  activarOpcion(opcionActiva: number) {
    if (opcionActiva === this.opcionActiva) {
      return;
    }
    this.opcionActiva = opcionActiva;
  }

  agregarMapa(imagen, Clinicas: any) {
    Clinicas.forEach((element) => {
      if (element.enabled === true) {
        this.capas.push(
          marker([element.latitude, element.longitude], {
            icon: icon({
              iconSize: [41, 41],
              iconAnchor: [41, 41],
              iconUrl: '../assets/location-pin.png',
              iconRetinaUrl: '../assets/location-pin.png',
            }),
          }).bindPopup(
            `<div class="d-flex justify-content-center"><img src=${imagen}  style="width:80px;height:80px;border-radius:50%;" /></div><p>${element.name} <br> ${element.address} <br> ${element.phoneNumber}</p>`
          )
        );
        //
      }

      //
    });
  }
}

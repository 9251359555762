<div class="locations">
  <h5 class="titulo" (click)="definir(); open(content)">
    <span class="mas">
      <img src="../assets/plus.png" alt="agregar" />
    </span>
    Ubicaciones
  </h5>
</div>
<div class="abajo">
  <div class="especialidades mb-1" *ngFor="let clinic of clinicaAuxiliar">
    <h5
      class="especialidad row d-flex justify-content-center align-items-center"
      (click)="modificarClinica(clinic.centerId)"
    >
      <div class="col-3 clinic-name maximo">{{ clinic.name }}</div>
      <div class="col-3 clinic-name maximo">{{ clinic.address }}</div>
      <div class="col-3 clinic-name">{{ clinic.phoneNumber }}</div>
      <span
        class="cancelar col-3"
        (click)="quitarClinica(clinic.name, clinic.centerId, clinic)"
        ><img src="../assets/cancel.png" alt="eliminar"
      /></span>
    </h5>
    <!-- <h5 class="especialidad" (click)="modificarClinica(clinic.centerId)">
        {{ clinic.name }}
        <span
          class="cancelar"
          (click)="quitarClinica(clinic.name, clinic.centerId, clinic)"
          ><img src="../assets/cancel.png" alt="eliminar"
        /></span>
      </h5> -->
  </div>
  <div class="d-flex justify-content-center" *ngIf="loader">
    <span class="loader2"></span>
  </div>
</div>
<!-- modal -->
<ng-template #content let-modal>
  <div class="modal-body">
    <div class="cerrar">
      <a
        class="cerrar-boton"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <h4>Agregar Ubicación</h4>
    <div class="row">
      <div class="col-12 col-xl-12 justify-content-center align-items-center">
        <form
          [formGroup]="form"
          class="d-flex justify-content-center align-items-center flex-column"
        >
          <div class="form-group col-12 col-xl-8">
            <label for="">Nombre:</label>
            <input type="text" class="form-control" formControlName="name" />
          </div>
          <div class="form-group col-12 col-xl-8">
            <label for="">Teléfono:</label>
            <input
              type="text"
              class="form-control col-12 col-xl-12"
              formControlName="phone"
              (keypress)="keyPress($event)"
            />
          </div>
          <div class="form-group col-12 col-xl-8">
            <label for="">Dirección:</label>
            <input
              type="text"
              class="form-control col-12 col-xl-12"
              formControlName="address"
            />
          </div>
          <div class="form-group col-12 col-xl-8">
            <label for="">Correo:</label>
            <input
              type="email"
              class="form-control col-12 col-xl-12"
              formControlName="email"
            />
          </div>
          <mat-error *ngIf="form.invalid"
            >Debe Ingresar al menos el nombre de la clínica</mat-error
          >

          <button
            type="button"
            class="btn boton boton2 col-12 col-xl-5"
            (click)="onsubmit()"
            [disabled]="form.invalid"
            *ngIf="accion === 0"
          >
            Agregar
          </button>
          <button
            type="button"
            class="btn boton boton2 col-12 col-xl-5"
            (click)="onsubmit()"
            [disabled]="form.invalid"
            *ngIf="accion === 1"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn boton boton2 col-12 col-xl-5"
            (click)="onsubmit()"
            [disabled]="form.invalid"
            *ngIf="accion === 2"
          >
            Actualizar
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- modal -->
<ng-template #content2 let-modal>
  <div class="modal-body modal-body-2">
    <div class="cerrar">
      <a
        class="cerrar-boton"
        aria-label="Close"
        #closebutton
        data-dismiss="modal"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <h4>Ubica tu clínica</h4>
    <app-mapa></app-mapa>
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn boton boton3 col-2"
        (click)="modal.dismiss('Cross click')"
      >
        Agregar
      </button>
    </div>
  </div>
</ng-template>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu-home',
  templateUrl: './side-menu-home.component.html',
  styleUrls: ['./side-menu-home.component.css'],
})
export class SideMenuHomeComponent implements OnInit {
  @Input() opcionActiva: number = 1;
  estadoResponse: any;
  mensaje: string = '';
  validado: boolean = true;

  @Output() opcion: EventEmitter<number> = new EventEmitter<number>();
  constructor(private router: Router) {}

  ngOnInit(): void {}

  getClaseCSS(opcion: number) {
    return opcion === this.opcionActiva
      ? 'options-sidemenu active'
      : 'options-sidemenu';
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }

  activarOpcion(opcionActiva: number) {
    if (opcionActiva === this.opcionActiva) {
      return;
    }
    this.opcionActiva = opcionActiva;
    window.scroll(0, 0);
    this.opcion.emit(this.opcionActiva);
  }
}

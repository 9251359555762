<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 fondo2 vh-100 d-none d-md-block"></div>
    <div class="col-md-6">
      <div
        class="d-flex flex-lg-row flex-column justify-content-center align-items-center vh-100 pt-1 pt-lg-5"
      >
        <div class="logo-esquina">
          <div class="logo">
            <img src="../assets/Logo Completo.png" alt="logo" />
          </div>
          <h2 class="texto">Iniciar Sesión</h2>
        </div>
        <form
          id="loginForm"
          class="formulario pt-5"
          [formGroup]="form"
          (submit)="onsubmit()"
        >
          <div class="form-group">
            <label for="email">Usuario *</label>
            <input
              name="email"
              type="email"
              formControlName="email"
              class="form-control form-control-sm form-control-lg"
              placeholder="Correo electrónico"
            />
            <!-- <mat-error *ngIf="form.invalid">{{
                obtenerErrorUsuario()
              }}</mat-error> -->
          </div>
          <div class="form-group">
            <label for="password">Contraseña*</label>
            <input
              name="password"
              type="password"
              formControlName="password"
              class="form-control form-control-sm form-control-lg"
              placeholder="Contraseña"
            />
            <div *ngFor="let error of errorLogin">
              <mat-error>{{ error }}</mat-error>
            </div>
          </div>
          <!-- <a href="#">¿Olvidaste tu contraseña?</a> -->
          <button
            class="btn botonIngresar"
            id="btnLogin"
            [disabled]="form.invalid"
          >
            Ingresar
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="carga" class="carga">
  <span class="loader"></span>
</div>

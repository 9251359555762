import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchDto } from '../utilidades/interfaceSearch';
import { SpecialitySearch } from '../utilidades/interfaceSpecialitySearch';
import { SeguridadService } from './seguridad.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private seguridadService: SeguridadService
  ) {}

  getSpecialism() {
    return this.http.get(`${this.apiUrl}api/Specialisms`);
  }

  getTypesSpecialism() {
    // return this.http.get(`api/Specialisms/Types`);
    return this.http.get(`${this.apiUrl}api/Specialisms/Types`);
  }

  specialismSearch(specialityId: number = 0, typeSpeciality: number = 0) {
    let link = 'api/Specialisms/adminSearch';

    if (specialityId !== 0) {
      link += `?specialismId=${specialityId}`;
      if (typeSpeciality !== 0) {
        link += `&specialismType=${typeSpeciality}`;
      }
    } else {
      if (typeSpeciality !== 0) {
        link += `?specialismType=${typeSpeciality}`;
      }
    }

    // console.log(link, specialityId, typeSpeciality, 'SearchEspeciality');

    // return this.http.get<SpecialitySearch[]>(`${link}`);
    return this.http.get<SpecialitySearch[]>(`${this.apiUrl}${link}`);
  }

  search(searchCriterio: string = '', specialityId: number = 0) {
    let link = 'api/Professionals/adminSearch';
    // specialityId = parseInt(specialityId.toString());
    if (searchCriterio !== '') {
      link += `?searchCriteria=${searchCriterio}`;
      if (specialityId !== 0) {
        link += `&specialtyId=${specialityId}`;
      }
    } else {
      if (specialityId !== 0) {
        // console.log(specialityId);
        link += `?specialtyId=${specialityId}`;
      }
    }

    // if (searchCriterio !== '' || specialityId !== 0) {
    // console.log(link, searchCriterio, specialityId, 'searchAdmin');
    // return this.http.get<SearchDto[]>(`${link}`);
    // return this.http.get<SearchDto[]>(`${link}`);
    return this.http.get<SearchDto[]>(`${this.apiUrl}${link}`);
    // }
  }
}

<div class="guardar" *ngIf="nickName !== 'DoctorClick'">
  <h5 (click)="open(content); accion = 1">
    <span class="mas">
      <img src="../assets/plus.png" alt="agregar" />
    </span>
    Asociaciones:
  </h5>
</div>
<div class="abajo">
  <div
    class="especialidades mb-1"
    *ngFor="let especialidad of especialidadArray"
    (click)="modificar(especialidad.associationId)"
  >
    <h5 class="especialidad row">
      <span class="col-10">{{ especialidad.name }}</span>
      <span
        class="cancelar col-2"
        (click)="quitarAsociacion(especialidad.associationId)"
        ><img src="../assets/cancel.png" alt="eliminar"
      /></span>
    </h5>
  </div>
</div>

<!-- modal -->
<ng-template #content let-modal>
  <div class="modal-body">
    <div class="cerrar">
      <a
        class="cerrar-boton"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <h4 class="text-center">Agregar Asociación:</h4>

    <div class="col-12 col-md-8 mx-auto">
      <form [formGroup]="form" class="d-flex flex-column align-items-center">
        <div class="form-group d-flex flex-column">
          <label for="">Nombre:</label>
          <input
            type="text"
            class="form-control col-12 col-xl-12 ml-3"
            formControlName="name"
          />
        </div>
        <div class="form-group d-flex flex-column">
          <label for="">Teléfono:</label>
          <input
            type="text"
            class="form-control col-12 col-xl-12 ml-3"
            formControlName="phone"
            (keypress)="keyPress($event)"
          />
        </div>
        <div class="form-group d-flex flex-column">
          <label for="">Dirección:</label>
          <input
            type="text"
            class="form-control col-12 ml-3"
            formControlName="address"
          />
        </div>
        <div class="form-group d-flex flex-column mb-4">
          <label for="">Correo:</label>
          <input
            type="email"
            class="form-control col-12 col-xl-12 ml-3"
            formControlName="email"
          />
        </div>

        <button
          type="button"
          class="btn boton boton2 col-12 col-xl-5"
          (click)="agregarAsociacion()"
          [disabled]="form.invalid"
          *ngIf="accion === 1"
        >
          <!-- *ngIf="accion === 0" -->
          Agregar
        </button>
        <button
          type="button"
          class="btn boton boton2 col-12 col-xl-5"
          (click)="modificarAsociacion()"
          [disabled]="form.invalid"
          *ngIf="accion === 2"
        >
          <!-- *ngIf="accion === 0" -->
          Actualizar
        </button>
      </form>
    </div>
  </div>
</ng-template>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DatosService } from 'src/app/services/datos.service';
import { SeguridadService } from 'src/app/services/seguridad.service';
import { Professional } from 'src/app/utilidades/interfaces';

@Component({
  selector: 'app-formulario-datos',
  templateUrl: './formulario-datos.component.html',
  styleUrls: ['./formulario-datos.component.css'],
})
export class FormularioDatosComponent implements OnInit {
  form: FormGroup;
  nickName;
  @Input() datos: Professional;
  prefijos;
  cargando: boolean = false;
  mensaje: string = 'Continuar';
  @Output() opcionActiva: EventEmitter<number> = new EventEmitter<number>();
  ocultar: boolean = false;
  errores: any = [];
  respuesta;

  idProfesional: number;
  userApp: string = '';
  respuestaUserId;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private datosService: DatosService,
    private seguridadService: SeguridadService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.datosService.getPrefixes().subscribe((response) => {
        this.prefijos = response;

        //
        if (params['us'] !== undefined) {
          this.userApp = params['us'];
          this.idProfesional = params['Pro'];

          this.datosService
            .getProfessionalByUserId(this.idProfesional)
            .subscribe((response) => {
              this.respuestaUserId = response;
              this.datosService.ProfesionalPut.profesionalId =
                this.respuestaUserId.id;
              this.datosService.ProfesionalPut.services =
                this.respuestaUserId.services;
              this.datosService.ProfesionalPut.profile =
                this.respuestaUserId.profile;
              this.datosService.ProfesionalPut.pictureProfileUrl =
                this.respuestaUserId.pictureProfileUrl;
              this.datosService.ProfesionalPut.nickName =
                this.respuestaUserId.nickName;
              this.datosService.ProfesionalPut.dpi = this.respuestaUserId.dpi;
              this.datosService.ProfesionalPut.id = this.idProfesional;
              this.datosService.ProfesionalPut.marriedName =
                this.respuestaUserId.marriedName;
              this.datosService.ProfesionalPut.phoneNumber =
                this.respuestaUserId.phoneNumber;
              this.datosService.ProfesionalPut.thirdName =
                this.respuestaUserId.thirdName;
              this.datosService.ProfesionalPut.address =
                this.respuestaUserId.address;
              this.datosService.ProfesionalPut.maritalStatusId = 0;
              this.datosService.ProfesionalPut.birthdate =
                this.respuestaUserId.birthdate;
              this.datosService.ProfesionalPut.prefixId =
                this.respuestaUserId.prefixId;

              this.form = this.formBuilder.group({
                prefix: [this.respuestaUserId.prefixId, Validators.required],
                firstName: [
                  this.respuestaUserId.firstName,
                  Validators.required,
                ],
                secondName: [this.respuestaUserId.secondName],
                surName: [this.respuestaUserId.surName, Validators.required],
                lastName: [this.respuestaUserId.lastName],
                collegeNumber: [this.respuestaUserId.collegiateNumber],
                sexo: [this.respuestaUserId.genderId, Validators.required],
              });
            });
        }
        //

        //
      });
    });
  }

  pachValoresConsulta() {}

  ngOnInit(): void {
    this.nickName = this.seguridadService.obtenerCampoJWT('NickName');
    this.datos = this.datosService.infoProfessional;

    if (this.userApp === '') {
      this.parchearValor();
    }

    // console.log(this.datos);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.datos !== undefined) {
      if (this.datos.id !== 0) {
        // console.log(this.datos);
        this.parchearValor();
      }
    }
  }

  keyPress2(event) {
    var charcode = event.which ? event.which : event.charCode;
    // console.log(charcode);
    if (charcode >= 65 && charcode <= 90) {
      return true;
    } else if (charcode >= 97 && charcode <= 122) {
      return true;
    } else if (charcode >= 48 && charcode <= 57) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  parchearValor() {
    this.form = this.formBuilder.group({
      prefix: ['', Validators.required],
      firstName: [this.datos.firstName, Validators.required],
      secondName: [this.datos.secondName],
      surName: [this.datos.surName, Validators.required],
      lastName: [this.datos.lastName],
      collegeNumber: [this.datos.collegiateNumber],
      sexo: ['', Validators.required],
    });
  }

  // guardar(e) {
  //   this.opcionActiva.emit(2);
  // }

  guardar(e) {
    this.datosService.infoProfessional.firstName = this.form.value.firstName;
    this.datosService.infoProfessional.secondName = this.form.value.secondName;
    this.datosService.infoProfessional.surName = this.form.value.surName;
    this.datosService.infoProfessional.lastName = this.form.value.lastName;
    this.datosService.infoProfessional.genderId = this.form.value.sexo;
    this.datosService.infoProfessional.collegiateNumber =
      this.form.value.collegeNumber;
    this.datosService.infoProfessional.prefixId = parseInt(
      this.form.value.prefix
    );
    this.datosService.ProfesionalPut.genderId =
      this.datosService.infoProfessional.genderId;
    this.datosService.ProfesionalPut.firstName =
      this.datosService.infoProfessional.firstName;
    this.datosService.ProfesionalPut.secondName =
      this.datosService.infoProfessional.secondName;
    this.datosService.ProfesionalPut.surName =
      this.datosService.infoProfessional.surName;
    this.datosService.ProfesionalPut.lastName =
      this.datosService.infoProfessional.lastName;
    this.datosService.ProfesionalPut.prefixId =
      this.datosService.infoProfessional.prefixId;

    this.datosService.ProfesionalPut.birthdate =
      this.userApp === ''
        ? moment().format()
        : this.datosService.ProfesionalPut.birthdate;

    // this.datosService.ProfesionalPut.birthdate = moment().format();
    this.datosService.ProfesionalPut.collegiateNumber =
      this.datosService.infoProfessional.collegiateNumber;

    this.cargando = true;
    if (this.userApp === '') {
      this.datosService
        .createProfessional(this.datosService.ProfesionalPut)
        .subscribe(
          (response) => {
            this.respuesta = response;
            localStorage.setItem('idP', this.respuesta.profesionalId);
            this.opcionActiva.emit(2);
            this.cargando = false;
          },
          (error) => {
            this.cargando = false;
            this.errores = [];
            console.log(error);
            if (error.status == 400) {
              error.error.forEach((element) => {
                // console.log(element);
                this.errores.push({ error: element });
              });

              console.log(this.errores);
            }
          }
        );
    } else {
      this.datosService
        .putProfesional(this.datosService.ProfesionalPut, this.userApp)
        .subscribe((response) => {
          this.opcionActiva.emit(2);
          this.cargando = false;
        });
    }

    // // console.log(this.datosService.infoProfessional);
  }

  regresar() {
    this.opcionActiva.emit(1);
  }

  keyPress(event) {
    var charcode = event.which ? event.which : event.charCode;
    // console.log(charcode);
    if (charcode < 48 || charcode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}

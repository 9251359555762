import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  tileLayer,
  latLng,
  LeafletMouseEvent,
  Marker,
  marker,
  icon,
} from 'leaflet';
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-especialidad-search',
  templateUrl: './especialidad-search.component.html',
  styleUrls: ['./especialidad-search.component.css'],
})
export class EspecialidadSearchComponent implements OnInit {
  especialidades;
  vermapa: boolean = false;
  busqueda;
  sinResultados: boolean = true;
  especialidad = 0;
  capas: Marker<any>[] = [];
  respuestaBusqueda: any[] = [];
  cargando: boolean = false;
  cantidad: number = 0;
  myControl = new FormControl();
  options2 = [];
  filteredOptions: Observable<string[]>;
  opcionActiva: number = 2;

  // Tipos de especialidad
  tipoEspcialidad;
  myControl2 = new FormControl();
  filteredOptions2: Observable<string[]>;
  options3 = [];
  @Output() cambio: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.searchService.getSpecialism().subscribe((response) => {
      this.especialidades = response;
      this.especialidades.forEach((element) => {
        this.options2.push(element);
      });

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter(name) : this.options2.slice()))
      );

      this.searchService.getTypesSpecialism().subscribe((response2) => {
        this.tipoEspcialidad = response2;
        this.tipoEspcialidad.forEach((element) => {
          this.options3.push(element);
        });

        // console.log(this.options3);

        this.filteredOptions2 = this.myControl2.valueChanges.pipe(
          startWith(''),
          map((value) => (typeof value === 'string' ? value : value.name)),
          map((name) => (name ? this._filter2(name) : this.options3.slice()))
        );
      });
      //
    });

    //
    this.cargando = true;
    this.searchService.specialismSearch(0, 0).subscribe((response) => {
      this.respuestaBusqueda = response;
      // console.log(this.respuestaBusqueda);
      this.cargando = false;
      this.sinResultados = false;
      // console.log(this.respuestaBusqueda);
    });
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  displayFn2(user): string {
    return user && user.name ? user.name : '';
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options2.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();
    // console.log(value);
    return this.options3.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  redireccion(idEspecialidad: any) {
    this.router.navigate([`/newEspeciality`], {
      queryParams: { Spe: idEspecialidad },
    });
  }

  changeOptionSearch(idSpecialidad) {
    // console.log(idSpecialidad);
    this.cambio.emit(idSpecialidad);
  }

  buscar2(e) {
    if (e.keyCode === 13) {
      this.buscar();
    }
  }

  buscar() {
    this.respuestaBusqueda = [];
    this.vermapa = false;
    this.sinResultados = false;
    this.cargando = true;
    this.cantidad = 0;

    // console.log(this.myControl.value);
    if (this.myControl.value === null || this.myControl.value === '') {
      this.especialidad = 0;
    } else {
      this.especialidad = this.myControl.value.id;
    }

    // console.log(this.myControl.value);
    if (this.myControl2.value === null || this.myControl2.value === '') {
      this.tipoEspcialidad = 0;
    } else {
      this.tipoEspcialidad = this.myControl2.value.id;
    }

    this.searchService
      .specialismSearch(
        parseInt(this.especialidad.toString()),
        parseInt(this.tipoEspcialidad.toString())
      )
      .subscribe((response) => {
        this.respuestaBusqueda = response;
        // console.log(this.respuestaBusqueda);
        this.cargando = false;
        // console.log(this.respuestaBusqueda);
      });
  }

  //
  changeOption() {}

  changeClassMenu() {}

  getClaseLugar(opcion) {
    return opcion === true || opcion === 1
      ? 'resultado row mb-2'
      : 'resultado-2 row mb-2';
  }
}

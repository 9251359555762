import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from '@ng-bootstrap/ng-bootstrap';
import { element } from 'protractor';
import { DatosService } from 'src/app/services/datos.service';
import { Clinic, Schedule } from 'src/app/utilidades/interfaces';

@Component({
  selector: 'app-formulario-clinica',
  templateUrl: './formulario-clinica.component.html',
  styleUrls: ['./formulario-clinica.component.css'],
})
export class FormularioClinicaComponent implements OnInit {
  @ViewChild('content') content: any;
  @ViewChild('closebutton') closebutton: ElementRef;
  @Input() bandera: number = 0;
  closeResult = '';
  error: boolean = false;
  clinica: Clinic[] = [];
  clinic: Clinic = {
    centerId: 0,
    professionalId: 0,
    name: '',
    address: '',
    phoneNumber: '',
    email: '',
    schedules: [],
    latitude: 0,
    longitude: 0,
  };
  horarios: Schedule[] = [];
  horarios2 = [];
  horario: Schedule = {
    clinicId: 0,
    day: 1,
    startTime: 0,
    endTime: 0,
  };
  form: FormGroup;
  form2: FormGroup;
  datosClinia;
  respuesta1;
  respuesta2;
  clinicaAuxiliar: Clinic[] = [];
  accion: number = 0;
  loader: boolean = false;
  modificarId;
  perfilValid: boolean = false;
  clinicId;
  virtualClinic: boolean = false;

  idProfessional: number = 0;
  respuestaClinicaProfesional;

  constructor(
    private modalService: NgbModal,
    private datosService: DatosService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['Pro'] !== undefined) {
        this.idProfessional = parseInt(params['Pro']);

        this.datosService
          .getClinicByProfessionalId(this.idProfessional)
          .subscribe((response) => {
            this.respuestaClinicaProfesional = response;
            this.llenarArreglo();
          });
      }

      if (localStorage.getItem('idP')) {
        this.idProfessional = parseInt(localStorage.getItem('idP'));
        this.datosService
          .getClinicByProfessionalId(this.idProfessional)
          .subscribe((response) => {
            this.respuestaClinicaProfesional = response;
            this.llenarArreglo();
          });
      }
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [this.clinic.name, Validators.required],
      phone: [this.clinic.phoneNumber, Validators.minLength(8)],

      address: [this.clinic.address],
      email: [this.clinic.email, [Validators.email]],
    });

    let horaInicio, horaFin;

    if (this.horario.startTime < 9) {
      horaInicio = '0' + this.horario.startTime + ':00';
    } else {
      horaInicio = this.horario.startTime + ':00';
    }

    if (this.horario.endTime < 9) {
      horaFin = '0' + this.horario.endTime + ':00';
    } else {
      horaFin = this.horario.endTime + ':00';
    }

    this.form2 = this.formBuilder.group({
      clinicId: [0],
      day: [this.horario.day],
      startTime: [horaInicio],
      endTime: [horaFin],
    });
  }

  limpiarFormulario() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [Validators.minLength(8)]],
      address: [''],
      email: ['', Validators.email],
    });

    let horaInicio, horaFin;

    if (this.horario.startTime < 9) {
      horaInicio = '0' + this.horario.startTime + ':00';
    } else {
      horaInicio = this.horario.startTime + ':00';
    }

    if (this.horario.endTime < 9) {
      horaFin = '0' + this.horario.endTime + ':00';
    } else {
      horaFin = this.horario.endTime + ':00';
    }

    this.form2 = this.formBuilder.group({
      clinicId: [0],
      day: [this.horario.day],
      startTime: [horaInicio],
      endTime: [horaFin],
    });

    this.horarios = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }

  onsubmit() {
    this.clinic.address = this.form.value.address;
    this.clinic.name = this.form.value.name;
    this.clinic.phoneNumber = this.form.value.phone;
    this.clinic.email = this.form.value.email;
    this.clinic.schedules = [];
    this.clinic.professionalId =
      this.idProfessional === 0
        ? parseInt(localStorage.getItem('idP'))
        : this.idProfessional;
    // this.clinic.professionalId = parseInt(localStorage.getItem('idP'));
    this.clinic.latitude = this.datosService.coordenadas.lat;
    this.clinic.longitude = this.datosService.coordenadas.lng;
    this.clinic.isVirtual = false;
    this.clinica = [];
    this.clinica.push(this.clinic);
    this.clinicaAuxiliar.push(this.clinic);
    this.datosService.auxiliar2 = this.clinicaAuxiliar;
    this.datosService.clinicas = [];
    this.datosService.clinicas = this.clinica;

    let aux = this.clinic;
    this.clinic = {
      centerId: 0,
      professionalId: 0,
      name: '',
      address: '',
      phoneNumber: '',
      email: '',
      schedules: [],
      latitude: 0,
      longitude: 0,
    };
    if (
      this.router.url.split('/')[1] === 'new' ||
      this.router.url.split('/')[1].split('?')[0] === 'new'
    ) {
      if (this.accion === 1) {
        this.datosService
          .postClinic(this.datosService.clinicas)
          .subscribe((response) => {
            this.clinicaAuxiliar = [];
            this.loader = true;
            this.datosService
              .getClinicByProfessionalId(this.idProfessional)
              .subscribe((response) => {
                this.respuestaClinicaProfesional = response;
                this.datosService.auxiliar2 = [];
                this.llenarArreglo();
                this.clinica = this.clinicaAuxiliar;
              });
          });
      } else if (this.accion === 2) {
        let auxHor = [];
        // console.log(this.horarios);
        this.horarios.forEach((element) => {
          auxHor.push({
            clinicId: this.clinicId,
            day: element.day,
            endTime: element.endTime,
            startTime: element.startTime,
          });
        });
        // console.log(auxHor);
        let pay = {
          id: this.modificarId,
          centerId: 1,
          professionalId: this.idProfessional,
          name: aux.name,
          address: aux.address,
          phoneNumber: aux.phoneNumber,
          email: aux.email,
          latitude: aux.latitude,
          longitude: aux.longitude,
          schedules: auxHor,
          isVirtual: aux.isVirtual,
        };
        this.datosService.putClinic(pay).subscribe((response) => {
          // console.log(response);
          this.clinicaAuxiliar = [];
          this.loader = true;
          this.datosService
            .getClinicByProfessionalId(this.idProfessional)
            .subscribe((response) => {
              this.respuestaClinicaProfesional = response;
              this.datosService.auxiliar2 = [];
              this.llenarArreglo();
              this.clinica = this.clinicaAuxiliar;
              this.datosService.coordenadas.lat = 14.642646401128106;
              this.datosService.coordenadas.lng = -90.51310122013093;
            });
          //
          this.clinica.pop();
          this.clinicaAuxiliar.pop();
        });
        // (pay);
        this.clinica.pop();
        this.clinicaAuxiliar.pop();
      }
    } else {
      this.datosService
        .postClinic(this.datosService.clinicas)
        .subscribe((response) => {
          response;
          this.clinicaAuxiliar = [];
          this.loader = true;
          this.datosService
            .getClinicByProfessionalId(this.idProfessional)
            .subscribe((response) => {
              response;
              this.respuestaClinicaProfesional = response;
              this.datosService.auxiliar2 = [];
              this.llenarArreglo();
              this.clinica = this.clinicaAuxiliar;
            });
        });
    }
    // (this.datosService.clinicas);
    this.modalService.dismissAll();
    this.limpiarFormulario();
  }

  quitarClinica(name: string, centerId: number, clinic) {
    // (clinic);
    // if (this.router.url.split('/')[1] === 'profile') {
    // (centerId);
    this.datosService.disableClinic(centerId).subscribe((response) => {
      response;
    });
    // }
    this.clinica = this.clinica.filter((element) => {
      if (
        element.address === clinic.address &&
        element.email === clinic.email &&
        element.name === clinic.name &&
        element.phoneNumber === clinic.phoneNumber
      ) {
        let i = 0;
        let comparados = 0;
        element.schedules.forEach((element) => {
          if (
            element.day === clinic.schedules[i].day &&
            element.startTime === clinic.schedules[i].startTime &&
            element.endTime === clinic.schedules[i].endTime
          ) {
            comparados++;
          }
          i++;
        });

        if (comparados !== element.schedules.length) {
          // (comparados, element.schedules.length);
          return element;
        }
        // (element);
      } else {
        // (element, itemFormacion);
        return element;
      }
    });
    this.clinicaAuxiliar = this.clinicaAuxiliar.filter((element) => {
      if (
        element.address === clinic.address &&
        element.email === clinic.email &&
        element.name === clinic.name &&
        element.phoneNumber === clinic.phoneNumber
      ) {
        let i = 0;
        let comparados = 0;
        element.schedules.forEach((element) => {
          if (
            element.day === clinic.schedules[i].day &&
            element.startTime === clinic.schedules[i].startTime &&
            element.endTime === clinic.schedules[i].endTime
          ) {
            comparados++;
          }
          i++;
        });

        if (comparados !== element.schedules.length) {
          // (comparados, element.schedules.length);
          return element;
        }
        // (element);
      } else {
        // (element, itemFormacion);
        return element;
      }
    });
  }

  modificarClinica(id: number = 0) {
    id;
    if (
      this.router.url.split('/')[1] === 'new' ||
      this.router.url.split('/')[1].split('?')[0] === 'new'
    ) {
      this.perfilValid = true;
      this.horarios = [];

      // (id);
      this.modificarId = id;
      this.clinicId = id;
      this.accion = 2;
      this.modalService.open(this.content);

      this.datosService.getClinicProfessional(id).subscribe((response) => {
        this.respuesta2 = response;
        this.form.patchValue({
          name: this.respuesta2.name,
          phone: this.respuesta2.phoneNumber,
          address: this.respuesta2.address,
          email: this.respuesta2.email,
        });
        this.datosService.coordenadas.lat = this.respuesta2.latitude;
        this.datosService.coordenadas.lng = this.respuesta2.longitude;
        this.virtualClinic = this.respuesta2.isVirtual;
        this.datosService.getScheduleClinic(id).subscribe((response2) => {
          this.respuesta1 = response2;
          this.horarios = this.respuesta1;
        });
      });
    }
  }

  agregarHorario() {
    if (this.form2.value.startTime !== '' || this.form2.value.endTime !== '') {
      this.error = false;
      this.form2.value.day = parseInt(this.form2.value.day);
      this.form2.value.startTime = parseInt(
        this.form2.value.startTime.split(':')[0]
      );
      this.form2.value.endTime = parseInt(
        this.form2.value.endTime.split(':')[0]
      );
      // console.log(this.form2.value);
      if (this.form2.value.startTime < this.form2.value.endTime) {
        let busqueda = this.horarios.filter((element) => {
          if (
            element.day === this.form2.value.day &&
            element.startTime === this.form2.value.startTime &&
            element.endTime === this.form2.value.endTime
          ) {
            // console.log('entre');
            return element;
          } else {
            // console.log(element);
          }
        });

        // console.log('-', busqueda[0]);

        if (busqueda[0] === undefined) {
          this.horarios.push(this.form2.value);
        }

        // console.log(this.horarios);
      }
    }
  }

  quitarHorario(horarioT, index) {
    // console.log(horarioT, index);
    // console.log(this.horarios);
    let i = 0;
    this.horarios = this.horarios.filter((x) => {
      if (index !== i) {
        // console.log(x, index, i);
        i++;
        return x;
      } else {
        i++;
      }
    });
  }

  transformPipe(dia) {
    switch (parseInt(dia)) {
      case 1:
        return 'Lunes';
        break;
      case 2:
        return 'Martes';
        break;
      case 3:
        return 'Miercoles';
        break;
      case 4:
        return 'Jueves';
        break;
      case 5:
        return 'Viernes';
        break;
      case 6:
        return 'Sabado';
        break;
      case 7:
        return 'Domingo';

        break;
    }
  }

  transformHour(hora) {
    if (hora < 9) {
      return '0' + hora + ':00';
    } else {
      return hora + ':00';
    }
  }

  keyPress(event) {
    var charcode = event.which ? event.which : event.charCode;
    // console.log(charcode);
    if (charcode < 48 || charcode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  definir() {
    if (
      this.router.url.split('/')[1] === 'new' ||
      this.router.url.split('/')[1].split('?')[0] === 'new'
    ) {
      this.accion = 1;
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        phone: ['', [Validators.minLength(8)]],
        address: [''],
        email: ['', Validators.email],
      });
      this.horarios = [];
    } else {
      this.accion = 0;
    }
  }

  llenarArreglo() {
    this.respuestaClinicaProfesional.forEach((element2) => {
      // console.log(element2.clinicId);
      this.datosService
        .getClinicProfessional(element2.id)
        .subscribe((response) => {
          // console.log(response);
          this.respuesta2 = response;
          let horarioaux: Schedule[] = [];
          this.datosService
            .getScheduleClinic(element2.id)
            .subscribe((response2) => {
              this.respuesta1 = response2;
              // console.log(this.respuesta1);
              this.respuesta1.forEach((element) => {
                horarioaux.push({
                  clinicId: element.id,
                  day: element.day,
                  startTime: element.startTime,
                  endTime: element.endTime,
                });
              });
            });

          this.clinicaAuxiliar.push({
            centerId: this.respuesta2.id,
            professionalId: this.idProfessional,
            name: this.respuesta2.name,
            address: this.respuesta2.address,
            phoneNumber: this.respuesta2.phoneNumber,
            email: this.respuesta2.email,
            schedules: horarioaux,
            latitude: this.respuesta2.latitude,
            longitude: this.respuesta2.longitude,
          });

          this.datosService.auxiliar2 = this.clinicaAuxiliar;
          // console.log(this.datosService.auxiliar2);
          this.loader = false;
        });
    });
    // console.log(this.clinicaAuxiliar);
    this.bandera = 0;
  }

  /*Alert*/
  // close(alert: Alert) {
  //   this.alerts.splice(this.alerts.indexOf(alert), 1);
  // }

  /*Modal*/
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}

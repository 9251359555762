import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-especiality',
  templateUrl: './new-especiality.component.html',
  styleUrls: ['./new-especiality.component.css'],
})
export class NewEspecialityComponent implements OnInit {
  opcionActiva = 1;

  constructor() {}

  ngOnInit(): void {
    localStorage.removeItem('idS');
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    localStorage.removeItem('idS');
  }

  eventoOpcionActivo(e) {
    this.opcionActiva = e;
  }

  cambiarOpcion(e) {
    this.opcionActiva = e;
  }

  regresar() {
    this.opcionActiva--;
  }

  clickIr() {
    this.opcionActiva++;
  }
}

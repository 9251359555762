<app-navbar></app-navbar>

<div class="contenedor">
  <div class="left pl-3">
    <div class="contenedor-blanco min-height-1">
      <div class="mid-container">
        <div *ngIf="step === 1" class="mt-5 pl-2">
          <h6 class="pl-5 pr-5 text-center">
            Dar click en el teléfono que estas verificando.
          </h6>
        </div>
        <div *ngIf="step === 2" class="mt-5 pl-2">
          <h6 class="pl-5 pr-5 text-center">Atendieron la llamada?</h6>
        </div>
        <div *ngIf="step === 3" class="mt-5 pl-2">
          <h6 class="pl-5 pr-5 text-center">
            SCRIPT<br />
            Buenos dias,<br />
            hablo al consultorio <br />
            de zona 10 del <br />
            DR. JUAN PEREZ?
          </h6>
        </div>
        <div *ngIf="step === 4" class="mt-5 pl-2">
          <h6 class="pl-5 pr-5 text-center">
            SCRIPT<br />
            los numeros<br />
            <span class="badge">2243-5580</span><br />
            <span class="badge">2243-5580</span><br />
            tambien sirven <br />
            para contactarles?
          </h6>
        </div>
        <div *ngIf="step === 5" class="mt-5 pl-2">
          <h6 class="pl-5 pr-5 text-center">
            El Dr. PEREZ, atiende las<br />
            especialidades:<br />
            <span class="badge">Cirugía Plastica</span><span>x</span><br />
            <span class="badge">Cirugía Cosmetica</span><span>x</span><br />
            tiene alguna otra <span>+</span>? <br />
          </h6>
        </div>
        <div *ngIf="step === 6" class="mt-5 pl-2">
          <h6 class="pl-5 pr-5 text-center">
            SCRIPT<br />
            En que horarios atiende<br />
            el Dr. PEREZ? <br />
          </h6>
        </div>
      </div>
      <div class="button-container mb-2 mt-2 d-flex" *ngIf="step !== 1">
        <button *ngIf="step === 2" (click)="goBackStep()">Telefono</button>
        <button *ngIf="step !== 2" (click)="goForwardStep()">Cancelar</button>
        <button (click)="goForwardStep()">Cheque</button>
      </div>
      <div class="comment-container" *ngIf="step !== 1">
        <h5 class="texto-azul">Comentario</h5>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          class="form-control"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="posting-container2">
    <div class="center-formulario">
      <h5 class="text-center texto-azul mb-5">Datos Profesionales</h5>
      <div class="mid-center-formulario"></div>
      <div class="bottom-center-formulario"></div>
    </div>
  </div>
  <div></div>
</div>

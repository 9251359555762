<div>
  <form (submit)="guardar($event)" [formGroup]="form">
    <h5 class="bold form-label">Datos Personales</h5>
    <div class="form-row">
      <div class="form-group col-12 col-lg-6">
        <label class="bold form-label">Prefijo *</label>
        <select class="form-control form-select" formControlName="prefix">
          <option value="0">Seleccione ...</option>
          <option [value]="item.id" *ngFor="let item of prefijos">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-12 col-lg-6">
        <label class="bold form-label">No. Colegiado</label>
        <input
          type="text"
          class="form-control"
          formControlName="collegeNumber"
          (keypress)="keyPress($event)"
        />
      </div>
    </div>
    <!--  -->
    <div class="form-row">
      <div class="form-group col-12 col-lg-6">
        <label class="bold form-label">Primer Nombre *</label>
        <input type="text" class="form-control" formControlName="firstName" />
      </div>
      <div class="form-group col-12 col-lg-6">
        <label class="bold form-label">Segundo Nombre</label>
        <input type="text" class="form-control" formControlName="secondName" />
      </div>
    </div>
    <!--  -->
    <div class="form-row">
      <div class="form-group col-12 col-lg-6">
        <label class="bold form-label">Primer Apellido *</label>
        <input type="text" class="form-control" formControlName="surName" />
      </div>
      <div class="form-group col-12 col-lg-6">
        <label class="bold form-label">Segundo Apellido</label>
        <input type="text" class="form-control" formControlName="lastName" />
      </div>
    </div>
    <!--  -->
    <div class="form-row">
      <div class="form-group col-12 col-lg-6">
        <label class="bold form-label">Sexo *</label>
        <select class="form-control form-select" formControlName="sexo">
          <option value="">Seleccione ...</option>
          <option value="1">Masculino</option>
          <option value="2">Femenino</option>
        </select>
      </div>
    </div>
    <!--  -->

    <!--  -->
    <div class="form-row mt-5">
      <div class="form-group col-12 col-lg-6 d-flex">
        <div class="form-row" *ngIf="form.invalid">
          <mat-error
            >* Falta completar la información necesaria para
            continuar</mat-error
          >
        </div>
        <mat-error *ngFor="let item of errores">
          <span>
            {{ item.error }}
          </span></mat-error
        >
      </div>
      <div class="form-group col-12 col-lg-6 d-flex">
        <button type="submit" class="btn boton" [disabled]="form.invalid">
          {{ mensaje }}
        </button>
      </div>
    </div>
    <!--  -->
  </form>
</div>

<div *ngIf="cargando" class="carga">
  <span class="loader"></span>
</div>

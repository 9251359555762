<div class="row">
  <div class="col-12 col-sm-2"></div>
  <div class="col-12 col-sm-3">
    <div class="form-group">
      <label class="form-label title-one-p">
        <span routerLink="/newEspeciality">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.64 20.64"
            class="iconos-svg"
          >
            <defs>
              <style>
                .cls-1 {
                  fill: #2a93cd;
                  stroke: #2a93cd;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 6px;
                }
              </style>
            </defs>
            <g id="Capa_2" data-name="Capa 2">
              <g id="Capa_1-2" data-name="Capa 1">
                <g id="Capa_1-3" data-name="Capa 1">
                  <line
                    id="Línea_8"
                    data-name="Línea 8"
                    class="cls-1"
                    x1="10.27"
                    y1="3"
                    x2="10.37"
                    y2="17.64"
                  />
                  <line
                    id="Línea_9"
                    data-name="Línea 9"
                    class="cls-1"
                    x1="17.64"
                    y1="10.23"
                    x2="3"
                    y2="10.41"
                  />
                </g>
              </g>
            </g>
          </svg>
          Especialidad</span
        >
      </label>
      <!-- <select class="form-control" [(ngModel)]="especialidad">
            <option value="0">Seleccione ...</option>
            <option *ngFor="let item of especialidades" [value]="item.id">
              {{ item.name }}
            </option>
          </select> -->
      <mat-form-field
        class="example-full-width"
        appearance="none"
        style="width: 100%"
      >
        <input
          type="text"
          aria-label="Number"
          matInput
          class="form-control"
          placeholder="Seleccione ..."
          [formControl]="myControl"
          [matAutocomplete]="auto1"
        />
        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div class="col-12 col-sm-3">
    <div class="form-group">
      <label class="form-label title-one-p">Tipo</label>

      <mat-form-field
        class="example-full-width"
        appearance="none"
        style="width: 100%"
      >
        <input
          type="text"
          aria-label="Number"
          matInput
          class="form-control"
          placeholder="Seleccione ..."
          [formControl]="myControl2"
          [matAutocomplete]="auto2"
        />
        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn2">
          <mat-option
            *ngFor="let option of filteredOptions2 | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="col-12 col-sm-4 pl-5 pr-5 row">
    <div class="d-flex align-items-center pl-1 col-2">
      <button class="btn boton" (click)="buscar()">
        <img src="../assets/search.svg" alt="" class="iconos" />
      </button>
    </div>
  </div>
</div>
<div class="resultado-container pt-4">
  <div class="row">
    <div class="col-3">
      <span class="title-one-p">Especialidad</span>
    </div>
    <div class="col-3">
      <span class="title-one-p">Asociaciones</span>
    </div>
    <div class="col-3">
      <span class="title-one-p">Tipo</span>
    </div>
    <div class="col-3 d-flex justify-content-center">
      <span class="title-one-p">Profesionales</span>
    </div>
  </div>
  <div class="resultados" *ngIf="!vermapa">
    <div
      class="resultado row mb-2"
      *ngFor="let item of respuestaBusqueda"
      (click)="redireccion(item.id)"
    >
      <div class="col-12 col-sm-3 d-flex align-items-center">
        <span class="pr-1 nombre">{{ item.name }} </span>
      </div>
      <div class="col-12 col-sm-3 d-flex flex-column">
        <span *ngFor="let item2 of item.associations">
          <span class="mas">+</span>
          {{ item2.name }}</span
        >
      </div>
      <div class="col-12 col-sm-3 d-flex flex-column">
        <span class="col-12 col-xl-10">
          <span class="mas">+</span>{{ item.specialismType }}
        </span>
      </div>
      <div class="col-12 col-sm-3 flex-column">
        <span
          class="mb-1 p-2 align-items-center d-flex text-center justify-content-center"
          ><span
            class="badge pl-5 pr-5"
            (click)="changeOptionSearch(item.id)"
            >{{ item.quantity }}</span
          ></span
        >
      </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="sinResultados">
      Sin Resultados
    </div>
    <div *ngIf="cargando" class="carga">
      <span class="loader"></span>
    </div>
  </div>
</div>

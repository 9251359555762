import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tick } from '@angular/core/testing';
import { environment } from 'src/environments/environment';
import { AsociationPost } from '../utilidades/interfaceAsociation';
import {
  Clinic,
  Experience,
  Post,
  Professional,
  ProfessionalDTO,
  Specialism,
  SpecialismCreatorDto,
  TicketCreatorDto,
  TicketCreatorInfoDto,
  TicketDto,
  Training,
} from '../utilidades/interfaces';
import { SeguridadService } from './seguridad.service';

@Injectable({
  providedIn: 'root',
})
export class DatosService {
  private apiUrl = environment.apiUrl;

  userId;
  private _infoProfessional: Professional = {
    id: 0,
    genderId: 0,
    maritalStatusId: 0,
    firstName: '',
    secondName: '',
    thirdName: '',
    surName: '',
    lastName: '',
    marriedName: '',
    pictureProfileUrl: '',
    birthdate: '',
    dpi: '',
    address: '',
    collegiateNumber: '',
    profile: '',
    phoneNumber: '',
    nickName: '',
    prefixId: 0,
    services: '',
  };

  public get infoProfessional(): Professional {
    return this._infoProfessional;
  }
  public set infoProfessional(value: Professional) {
    this._infoProfessional = value;
  }
  //
  private _especialidades = [];
  public get especialidades() {
    return this._especialidades;
  }
  public set especialidades(value) {
    this._especialidades = value;
  }
  //
  private _coordenadas = {
    lat: 14.642646401128106,
    lng: -90.51310122013093,
  };
  public get coordenadas() {
    return this._coordenadas;
  }
  public set coordenadas(value) {
    this._coordenadas = value;
  }

  //
  private _clinicas: Clinic[] = [];
  public get clinicas(): Clinic[] {
    return this._clinicas;
  }
  public set clinicas(value: Clinic[]) {
    this._clinicas = value;
  }
  //
  private _experiencia: Experience[] = [];
  public get experiencia(): Experience[] {
    return this._experiencia;
  }
  public set experiencia(value: Experience[]) {
    this._experiencia = value;
  }
  //
  private _formacion: Training[] = [];
  public get formacion(): Training[] {
    return this._formacion;
  }
  public set formacion(value: Training[]) {
    this._formacion = value;
  }
  //
  private _specialism: Specialism[] = [];
  public get specialism(): Specialism[] {
    return this._specialism;
  }
  public set specialism(value: Specialism[]) {
    this._specialism = value;
  }

  //
  private _ProfesionalPut: ProfessionalDTO = {
    id: 0,
    nickName: '',
    genderId: 0,
    maritalStatusId: 0,
    firstName: '',
    secondName: '',
    thirdName: '',
    surName: '',
    lastName: '',
    marriedName: '',
    pictureProfileUrl: '',
    birthdate: undefined,
    dpi: '',
    address: '',
    collegiateNumber: '',
    profile: '',
    specialisms: [],
    clinics: [],
    schedules: [],
    experiences: [],
    trainings: [],
    phoneNumber: '',
    prefixId: 0,
    services: '',
  };
  public get ProfesionalPut(): ProfessionalDTO {
    return this._ProfesionalPut;
  }
  public set ProfesionalPut(value: ProfessionalDTO) {
    this._ProfesionalPut = value;
  }
  /** */
  imagenFile: File;
  private _imagenBase64: string = '';
  public get imagenBase64(): string {
    return this._imagenBase64;
  }
  public set imagenBase64(value: string) {
    this._imagenBase64 = value;
  }

  constructor(
    private seguridadService: SeguridadService,
    private http: HttpClient
  ) {}

  getPosts() {
    let nickName = localStorage.getItem('NickName');
    return this.http.get(
      `${this.apiUrl}api/Posts/nickName?nickName=${nickName}`
    );
  }

  getProfesional() {
    this.userId = localStorage.getItem('NickName');
    return this.http.get<Professional>(
      `${this.apiUrl}api/Professionals/nickName?nickName=${this.userId}`
    );
  }

  createProfessional(payload: ProfessionalDTO) {
    // console.log(payload);
    // return this.http.put(`api/Professionals/CreateProffesional`, payload);
    return this.http.put(
      `${this.apiUrl}api/Professionals/CreateProffesional`,
      payload
    );
  }

  getPostById(id) {
    return this.http.get(`${this.apiUrl}api/Posts/postId?postId=${id}`);
  }

  getProfesionalUserId() {
    this.userId = this.seguridadService.obtenerCampoJWT('UserId');
    return this.http.get<Professional>(
      `${this.apiUrl}api/Professionals/${this.userId}`
    );
  }

  getProfessionalByUserId(appUserId: any) {
    // return this.http.get<Professional>(`api/Professionals/${appUserId}`);
    return this.http.get<Professional>(
      `${this.apiUrl}api/Professionals/professionalId?professionalId=${appUserId}`
    );
    // return this.http.get<Professional>(
    //   `${this.apiUrl}api/Professionals/${appUserId}`
    // );
  }

  putProfesional(payload, appUserId: string = '') {
    // console.log(payload);
    // return this.http.put(`api/Professionals/${appUserId}`, payload);
    return this.http.put(
      `${this.apiUrl}api/Professionals/${appUserId}`,
      payload
    );
  }

  getSpecialism() {
    return this.http.get(`${this.apiUrl}api/Specialisms`);
  }

  getSpecialisById(idEspecialidad) {
    return this.http.get(`${this.apiUrl}api/Specialisms/${idEspecialidad}`);
    // return this.http.get(`api/Specialisms/${idEspecialidad}`);
  }

  getSpecialismsByProfessionalId(idProfesional: number = 0) {
    // return this.http.get(`api/Specialisms/professional/${idProfesional}`);
    return this.http.get(
      `${this.apiUrl}api/Specialisms/professional/${idProfesional}`
    );
  }

  putSpecialismsById(idEspecialidad: number = 0, objeto: SpecialismCreatorDto) {
    // console.log(idEspecialidad, objeto);
    return this.http.put(
      `${this.apiUrl}api/Specialisms/${idEspecialidad}`,
      objeto
    );
  }

  getInterests() {
    return this.http.get(`${this.apiUrl}api/Patients/interests`);
  }

  publicarPost(payload: Post, interes1 = 0, interes2 = 0, interes3 = 0) {
    let payloadPost;
    payloadPost = {
      detail: payload.detail,
      professionalId: payload.professionalId,
      specialismId: payload.specialismId,
      title: payload.title,
      audiovisuals: payload.audiovisuals,
      interes1: interes1,
    };

    if (interes2 !== 0) {
      payloadPost = {
        detail: payload.detail,
        professionalId: payload.professionalId,
        specialismId: payload.specialismId,
        title: payload.title,
        audiovisuals: payload.audiovisuals,
        interes1: interes1,
        interes2: interes2,
      };
    }

    if (interes3 !== 0) {
      payloadPost = {
        detail: payload.detail,
        professionalId: payload.professionalId,
        specialismId: payload.specialismId,
        title: payload.title,
        audiovisuals: payload.audiovisuals,
        interes1: interes1,
        interes2: interes2,
        interes3: interes3,
      };
    }

    // console.log(payloadPost);
    return this.http.post(`${this.apiUrl}api/Posts`, payloadPost);
    // return this.http.post(`api/Posts`, payloadPost);
  }

  putPost(payload: Post, interes1 = 0, interes2 = 0, interes3 = 0, postId) {
    let payloadPost;
    payloadPost = {
      detail: payload.detail,
      professionalId: payload.professionalId,
      specialismId: payload.specialismId,
      title: payload.title,
      audiovisuals: payload.audiovisuals,
      interes1: interes1,
    };

    if (interes2 !== 0) {
      payloadPost = {
        detail: payload.detail,
        professionalId: payload.professionalId,
        specialismId: payload.specialismId,
        title: payload.title,
        audiovisuals: payload.audiovisuals,
        interes1: interes1,
        interes2: interes2,
      };
    }

    if (interes3 !== 0) {
      payloadPost = {
        detail: payload.detail,
        professionalId: payload.professionalId,
        specialismId: payload.specialismId,
        title: payload.title,
        audiovisuals: payload.audiovisuals,
        interes1: interes1,
        interes2: interes2,
        interes3: interes3,
      };
    }

    // console.log(payloadPost, postId);

    return this.http.put(
      `${this.apiUrl}api/Posts?postId=${postId}`,
      payloadPost
    );
  }

  uploadImage(imagen: File) {
    const formData = new FormData();
    formData.append('File', imagen);

    return this.http.post(`${this.apiUrl}api/Files`, formData);
  }

  obtenerImagen() {
    this.userId = this.seguridadService.obtenerCampoJWT('UserId');
    return this.http.get(
      `${this.apiUrl}api/Accounts/user/pictureProfile/${this.userId}?userId=${this.userId}`
    );
  }

  getClinicProfessional(clinicId) {
    return this.http.get(`${this.apiUrl}api/Professionals/clinic/${clinicId}`);
  }

  getScheduleClinic(clinicId) {
    return this.http.get(
      `${this.apiUrl}api/Professionals/clinic/schedule/${clinicId}`
    );
  }

  getPrefixes() {
    return this.http.get(`${this.apiUrl}api/Professionals/prefixes`);
  }

  deleteSpecialism(id, id2) {
    // console.log(id, id2);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        professionalId: id,
        specialismId: id2,
      },
    };

    return this.http.delete(
      `${this.apiUrl}api/Specialisms/professional`,
      options
    );
  }

  getSpecialismProfesional(profesionalId) {
    return this.http.get(
      `${this.apiUrl}api/Professionals/specialisms/${profesionalId}`
    );
  }

  postSpecialism(id, specialismId) {
    let payload = {
      professionalId: id,
      specialismId: specialismId,
    };

    // return this.http.post(`api/Specialisms/professional`, payload);
    return this.http.post(
      `${this.apiUrl}api/Specialisms/professional`,
      payload
    );
  }

  createSpecialism(createSpecialismDto: SpecialismCreatorDto) {
    return this.http.post(`${this.apiUrl}api/Specialisms`, createSpecialismDto);
    // return this.http.post(`api/Specialisms`, createSpecialismDto);
  }

  //Asociaciones
  postAsociation(asociationObject: AsociationPost) {
    // console.log(asociationObject);
    // return this.http.post(`api/Association`, asociationObject);
    return this.http.post(`${this.apiUrl}api/Association`, asociationObject);
  }

  getAssociationById(idAsociation: number) {
    // return this.http.get(`api/Association/${idAsociation}`);
    return this.http.get(`${this.apiUrl}api/Association/${idAsociation}`);
  }

  putAssociation(idAsociation: number = 0, asociationObject: AsociationPost) {
    // return this.http.put(`api/Association/${idAsociation}`, asociationObject);
    return this.http.put(
      `${this.apiUrl}api/Association/${idAsociation}`,
      asociationObject
    );
  }

  disableAssociation(idAsociation: number = 0, idSpecialism: number = 0) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        associationId: idAsociation,
        specialismId: idSpecialism,
      },
    };

    // return this.http.delete(`api/Specialisms/associations`, options);
    return this.http.delete(
      `${this.apiUrl}api/Specialisms/associations`,
      options
    );
  }

  realtionAssociation(idAsociation: number = 0, idSpeciality: number = 0) {
    let payload = {
      associationId: idAsociation,
      specialismId: idSpeciality,
    };

    // console.log(payload);
    // return this.http.post(`api/Association/Specialism`, payload);
    return this.http.post(`${this.apiUrl}api/Association/Specialism`, payload);
  }

  obtaionAssociationsBySpecialisms(idSpecialism: number = 0) {
    // return this.http.get(`api/Specialisms/associations/${idSpecialism}`);
    return this.http.get(
      `${this.apiUrl}api/Specialisms/associations/${idSpecialism}`
    );
  }

  //

  postClinic(datos) {
    // console.log(datos);
    // return this.http.post(`api/Clinics`, datos[0]);
    return this.http.post(`${this.apiUrl}api/Clinics`, datos[0]);
  }

  putClinic(datos) {
    return this.http.put(`${this.apiUrl}api/Clinics/edit`, datos);
  }

  getClinicByProfessionalId(professionalId: number = 0) {
    // return this.http.get(`api/Clinics/professional/${professionalId}`);
    return this.http.get(
      `${this.apiUrl}api/Clinics/professional/${professionalId}`
    );
  }

  disableClinic(clinicId: number) {
    let payload = {
      clinicId: clinicId,
    };
    // console.log(payload);
    return this.http.put(
      `${this.apiUrl}api/Clinics/disable?clinicId=${clinicId}`,
      payload
    );
  }

  auxiliar2;
}
